class Map {
  map: HTMLElement;
  constructor() {
    this.map = document.getElementById("map") as HTMLElement;
  }

  init = () => {
    if (!this.map) return;
    window.mapboxgl.accessToken = window.map_vars.key;
    const map = new window.mapboxgl.Map({
      container: this.map.id,
      style: window.map_vars.styles,
      center: [21.057995727123565, 50.35839520356923],
      zoom: 12,
    });

    const markerEl = document.createElement("div");
    markerEl.classList.add(".map-marker");
    markerEl.style.backgroundImage = `url('${window.map_vars.pin}')`;
    markerEl.style.width = "50px";
    markerEl.style.height = "100px";
    markerEl.style.backgroundSize = "contain";
    markerEl.style.backgroundPosition = "center top";
    markerEl.style.backgroundRepeat = "no-repeat";

    const marker = new window.mapboxgl.Marker(markerEl)
      .setLngLat(
        window.map_vars.coordinates
          .split(";")
          .map((coord) => Number(Number(coord).toFixed(5)))
          .reverse()
      )
      .addTo(map);
  };
}

export default Map;
