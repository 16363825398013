import { disableScroll, enableScroll, mobileBreakpoint } from "./helpers";
class Header {
  root = document.querySelector("#header") as HTMLElement;
  hamburgerBtn = document.querySelector(".hamburger") as HTMLButtonElement;

  events: { [key: string]: Event } = {};

  scrolled = false;
  menuOpen = false;

  MENU_OPEN_CLASS = "menu-open";
  SCROLLED_CLASS = "scrolled";

  constructor() {
    this.initEvents();
  }

  initEvents = () => {
    this.events["header-scroll"] = new Event("header-scroll");
    this.events["header-back"] = new Event("header-back");
    this.events["menu-open"] = new Event("menu-open");
    this.events["menu-close"] = new Event("menu-close");
  };

  init = (): void => {
    this.handleScroll();
    this.handleMobileMenu();
  };

  handleScroll = () => {
    this.root.addEventListener("header-scroll", () => {
      this.root.classList.add(this.SCROLLED_CLASS);
    });

    this.root.addEventListener("header-back", () => {
      this.root.classList.remove(this.SCROLLED_CLASS);
    });

    window.addEventListener("scroll", () => {
      let scrollState = this.scrolled;

      if (scrollY > 120 && this.scrolled === false) scrollState = true;
      else if (scrollY < 120 && this.scrolled === true) scrollState = false;

      if (scrollState === this.scrolled) return;

      this.scrolled = scrollState;
      this.root.dispatchEvent(
        this.scrolled
          ? this.events["header-scroll"]
          : this.events["header-back"]
      );
    });
  };

  handleMobileMenu = () => {
    this.root.addEventListener("menu-open", () => {
      disableScroll();
      this.menuOpen = true;

      this.root.classList.add(this.MENU_OPEN_CLASS);
    });

    this.root.addEventListener("menu-close", () => {
      this.root.classList.remove(this.MENU_OPEN_CLASS);

      this.menuOpen = false;
      enableScroll();
    });

    this.hamburgerBtn.addEventListener("click", () => {
      this.root.dispatchEvent(
        this.menuOpen ? this.events["menu-close"] : this.events["menu-open"]
      );
    });

    window.addEventListener("keydown", (e) => {
      e.key === "Escape" &&
        this.menuOpen &&
        this.root.dispatchEvent(this.events["menu-close"]);
    });

    window.addEventListener("resize", () => {
      innerWidth > mobileBreakpoint &&
        this.menuOpen &&
        this.root.dispatchEvent(this.events["menu-close"]);
    });
  };
}

export default Header;
