import { Splide } from "@splidejs/splide";
import LazyLoadImages from "./components/LazyLoadImages";
import "@splidejs/splide/css/core";
import Header from "./components/Header";
import Map from "./components/Map";
import Wpcf7CustomFile from "./plugins/Wpcf7CustomFile";
import Cookies from "./components/Cookies";

require("fslightbox");

window.addEventListener("DOMContentLoaded", () => {
  (<any>window).lazy = new LazyLoadImages();

  new Wpcf7CustomFile();

  new Header().init();
  new Map().init();
  new Cookies().init();

  if (document.body.classList.contains("home")) {
    new Splide(".hero-slider", {
      arrows: false,
    }).mount();
  }
});

window.addEventListener("resize", () => {
  (<any>window).lazy.update();
});
