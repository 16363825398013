class Wpcf7CustomFile {
  fileInputs: NodeListOf<HTMLElement>;
  constructor() {
    this.fileInputs = document.querySelectorAll<HTMLElement>(".cf-container");

    if (this.fileInputs.length !== 0) {
      this.initEvents();
    }
  }

  initEvents = () => {
    this.fileInputs.forEach(function (container) {
      const elementName = container.querySelector(
        ".cf-file-name"
      ) as HTMLElement;
      const deleteBtn = container.querySelector(
        ".cf-delete-selected"
      ) as HTMLElement;
      const button = container.querySelector(".cf-button") as HTMLElement;
      const input = container.querySelector(
        '[type="file"]'
      ) as HTMLInputElement;

      const wpcf7 =
        container.parentElement?.parentElement?.parentElement?.parentElement;
      console.log(wpcf7);

      wpcf7?.addEventListener("wpcf7mailsent", (e) => {
        input.value = "";
        elementName.classList.add("hidden");
        elementName.innerHTML = "";
        elementName.removeAttribute("title");
        deleteBtn.classList.add("hidden");
        button.classList.remove("change");
      });

      if (!input) return;

      input.addEventListener("change", () => {
        if (!input.files) return;

        const fileName = input.files[0].name;

        if (fileName) {
          elementName.innerHTML = fileName;
          elementName.setAttribute("title", fileName);
          elementName.classList.remove("hidden");
          deleteBtn.classList.remove("hidden");
          button.classList.add("change");
        } else {
          elementName.classList.remove("visible");
          deleteBtn.classList.remove("visible");
          button.classList.add("change");
        }
      });

      deleteBtn.addEventListener("click", (e) => {
        e.stopPropagation();
        e.preventDefault();
        input.value = "";
        elementName.classList.add("hidden");
        elementName.innerHTML = "";
        elementName.removeAttribute("title");
        deleteBtn.classList.add("hidden");
        button.classList.remove("change");
      });
    });
  };
}

export default Wpcf7CustomFile;
